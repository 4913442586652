import { useMemo } from 'react'
import { AlarmTypes, getNameTranslationKey } from '../../../redux/alarm-settings/alarm-types'
import { useTranslation } from 'react-i18next'

export interface AlarmTypeSelectOption {
  value: string
  label: string
}

export const useAlarmTypeSelectOptions = (): AlarmTypeSelectOption[] => {
  const t = useTranslation().t

  return useMemo(() => {
    return (Object.values(AlarmTypes)).map((alarmType) => ({
      value: alarmType.alarmKey,
      label: t(getNameTranslationKey(alarmType))
    })).sort((a, b) => a.label.localeCompare(b.label))
  }, [t])
}
