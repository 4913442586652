import { type Meter, type DeviceType } from '../redux/meters/meter-types'
import Fuse from 'fuse.js'
import { type AlarmType, supportedAlarmsByType } from '../redux/alarm-settings/alarm-types'

export const meterSupportsAlarm = (meterType: DeviceType, alarmType: AlarmType): boolean => {
  const supportedTypes: AlarmType[] | undefined = supportedAlarmsByType.get(meterType)
  return supportedTypes ? supportedTypes.includes(alarmType) : false
}

const fuseOptions = {
  includeScore: true,
  keys: ['loraId', 'sigfoxId', 'serialNumber', 'transmitterSerialNumber', 'location', 'labels'],
  threshold: 0
}

export const searchMeters = (searchText: string, meters: Meter[]) => {
  const tokenisedSearchQuery: string[] = searchText.match(/("[^"]*?"|[^"\s]+)+(?=\s*|\s*$)/g) || []
  if (tokenisedSearchQuery.length === 0) return meters
  meters = new Fuse(meters, fuseOptions).search({
    $and: tokenisedSearchQuery.map((searchToken: string) => {
      const orFields: Fuse.Expression[] = [
        { loraId: searchToken },
        { sigfoxId: searchToken },
        { serialNumber: searchToken },
        { transmitterSerialNumber: searchToken },
        { location: searchToken },
        { labels: searchToken }
      ]

      return {
        $or: orFields
      }
    })
  })
    .map(e => e.item)
  return meters
}
