import {
  FETCH_MAIN_METERS,
  FETCH_MAIN_METERS_FAILED,
  FETCH_MAIN_METERS_SUCCESSFUL,
  TOGGLE_MAIN_METERS_MODAL,
  ASSOCIATE_MAIN_METER_TO_LABEL_START,
  ASSOCIATE_MAIN_METER_TO_LABEL_SUCCESS,
  ASSOCIATE_MAIN_METER_TO_LABEL_ERROR,
  REMOVE_MAIN_WATER_METER_TO_LABEL_START,
  REMOVE_MAIN_WATER_METER_TO_LABEL_SUCCESSFUL,
  REMOVE_MAIN_WATER_METER_TO_LABEL_FAILED,
  MAIN_WATER_METER_CHANGED,
  LABEL_VALUE_CHOSEN,
  EXPAND_ONE_MAIN_METER_ROW
} from '../../constants/action-types'
import { createAction } from '@reduxjs/toolkit'
import { type FetchLabelsWithMainMetersSuccessfulPayload, type FetchLabelsWithMainMetersFailedPayload } from './main-meters-types'
import { type Device } from '../../interfaces/Device'

export const FetchLabelsWithMainMetersAction = createAction<void>(FETCH_MAIN_METERS)
export const FetchLabelsWithMainMetersSuccessfulAction = createAction<FetchLabelsWithMainMetersSuccessfulPayload>(FETCH_MAIN_METERS_SUCCESSFUL)
export const FetchLabelsWithMainMetersFailedAction = createAction<FetchLabelsWithMainMetersFailedPayload>(FETCH_MAIN_METERS_FAILED)
export const AssociateMainWaterMeterToLabelAction = createAction<FetchLabelsWithMainMetersSuccessfulPayload>(ASSOCIATE_MAIN_METER_TO_LABEL_START)
export const AssociateMainWaterMeterToLabelSuccessful = createAction<FetchLabelsWithMainMetersSuccessfulPayload>(ASSOCIATE_MAIN_METER_TO_LABEL_SUCCESS)
export const AssociateMainWaterMeterToLabelFailed = createAction<FetchLabelsWithMainMetersFailedPayload>(ASSOCIATE_MAIN_METER_TO_LABEL_ERROR)
export const ChooseLabelValue = createAction<FetchLabelsWithMainMetersSuccessfulPayload>(LABEL_VALUE_CHOSEN)
export const ChooseMainWaterMeter = createAction<Device>(MAIN_WATER_METER_CHANGED)
export const RemoveMainWaterMeterToLabelAction = createAction<FetchLabelsWithMainMetersSuccessfulPayload>(REMOVE_MAIN_WATER_METER_TO_LABEL_START)
export const RemoveMainWaterMeterToLabelSuccessful = createAction<FetchLabelsWithMainMetersSuccessfulPayload>(REMOVE_MAIN_WATER_METER_TO_LABEL_SUCCESSFUL)
export const RemoveMainWaterMeterToLabelFailed = createAction<FetchLabelsWithMainMetersFailedPayload>(REMOVE_MAIN_WATER_METER_TO_LABEL_FAILED)
export const ExpandOneMainMeterRowAction = createAction<string>(EXPAND_ONE_MAIN_METER_ROW)
export const ToggleMainMetersModalAction = createAction<boolean>(TOGGLE_MAIN_METERS_MODAL)
