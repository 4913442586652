import {
  FETCH_DEVICES_FOR_PROVISION_SUCCESS,
  FETCH_DEVICES_FOR_PROVISION_FAILED,
  UPLOAD_DEVICES_FOR_PROVISION_CSV_FAILED,
  UPLOAD_DEVICES_FOR_PROVISION_CSV_SUCCESS,
  OPEN_CREATE_DEVICE_FOR_PROVISION_DIALOG,
  CLOSE_CREATE_DEVICE_FOR_PROVISION_DIALOG,
  OPEN_EDIT_DEVICE_FOR_PROVISION_DIALOG,
  CLOSE_EDIT_DEVICE_FOR_PROVISION_DIALOG,
  NEW_DEVICE_FOR_PROVISION_VALUES_CHANGED,
  EDITED_DEVICE_FOR_PROVISION_VALUES_CHANGED,
  CREATE_DEVICE_FOR_PROVISION_SUCCESS,
  CREATE_DEVICE_FOR_PROVISION_FAILED,
  UPDATE_DEVICE_FOR_PROVISION_SUCCESS,
  UPDATE_DEVICE_FOR_PROVISION_FAILED,
  DELETE_DEVICE_FOR_PROVISION_FAILED,
  DELETE_DEVICE_FOR_PROVISION_SUCCESS,
  OPEN_IMPORT_DEVICES_FOR_PROVISION_DIALOG,
  CLOSE_IMPORT_DEVICES_FOR_PROVISION_DIALOG,
  IMPORT_DEVICES_FOR_PROVISION_FILE_CHANGED,
  IMPORT_DEVICES_FOR_PROVISION_PROFILE_ID_CHANGED,
  FETCH_DEVICE_PROFILES_FAILED,
  FETCH_DEVICE_PROFILES_SUCCESS,
  FETCH_DEVICE_PROFILES_STARTED,
  SET_DEVICES_ACTIVE_PAGE,
  SET_DEVICES_PAGE_SIZE,
  OPEN_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG,
  CLOSE_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG,
  NEW_GENERATE_CSV_FROM_SERIAL_VALUES_CHANGED,
  SEARCH_DEVICES_FOR_PROVISION
} from '../../constants/action-types.ts'

import createI18nInstance from '../../i18n'
import { retrieveLanguage } from '../../utils/languageStore'

const i18n = createI18nInstance(retrieveLanguage())

const initialDeviceForProvisionFields = {
  serialNumber: '',
  devEUI: '',
  appEUI: '',
  appKey: '',
  deviceProfileId: ''
}

export const initialDeviceForProvisionState = {
  devicesForProvision: [],
  editedDeviceForProvision: { ...initialDeviceForProvisionFields, id: '' },
  newDeviceForProvision: { ...initialDeviceForProvisionFields },
  csvExportStart: '',
  editDeviceForProvisionDialogOpen: false,
  createDeviceForProvisionDialogOpen: false,
  importDevicesForProvisionDialogOpen: false,
  importDevicesForProvisionFile: null,
  importDevicesForProvisionProfileId: '',
  deviceProfiles: [],
  loadingDeviceProfiles: false,
  deviceProfilesLoaded: false,
  devicesActivePage: 1,
  devicesPageSize: 10,
  searchQuery: ''
}

const activationDataFetched = 'Aktivointitietoja tuotu'
const units = 'kpl'

function devicesForProvisionReducer (state = initialDeviceForProvisionState, action) {
  switch (action.type) {
    case FETCH_DEVICES_FOR_PROVISION_SUCCESS:
      return {
        ...state,
        devicesForProvision: action.data
      }
    case FETCH_DEVICES_FOR_PROVISION_FAILED:
      window.alert(i18n.t('errors.fetch_devices_for_provision_failed' + ': ') + action.error)
      return state
    case FETCH_DEVICE_PROFILES_STARTED:
      return {
        ...state,
        loadingDeviceProfiles: true
      }
    case FETCH_DEVICE_PROFILES_SUCCESS:
      return {
        ...state,
        deviceProfiles: action.data,
        loadingDeviceProfiles: false,
        deviceProfilesLoaded: true
      }
    case FETCH_DEVICE_PROFILES_FAILED:
      window.alert(i18n.t('errors.fetch_device_profiles_failed') + ': ' + action.error)
      return {
        ...state,
        loadingDeviceProfiles: false
      }
    case UPLOAD_DEVICES_FOR_PROVISION_CSV_SUCCESS:
      if (action.data.error !== '') {
        window.alert(activationDataFetched + ' ' + action.data.returnValue.length + ' ' + units + ', virheitä: \n' + action.data.error)
      } else {
        window.alert(activationDataFetched + ' ' + action.data.returnValue.length + ' ' + units)
      }
      return {
        ...state,
        devicesForProvision: [...state.devicesForProvision, ...action.data.returnValue],
        importDevicesForProvisionFile: null,
        importDevicesForProvisionProfileId: '',
        importDevicesForProvisionDialogOpen: false
      }
    case UPLOAD_DEVICES_FOR_PROVISION_CSV_FAILED:
      window.alert(i18n.t('errors.upload_devices_for_provision_csv_failed') + ': ' + action.error)
      return state
    case OPEN_CREATE_DEVICE_FOR_PROVISION_DIALOG:
      return {
        ...state,
        createDeviceForProvisionDialogOpen: true
      }
    case CLOSE_CREATE_DEVICE_FOR_PROVISION_DIALOG:
      return {
        ...state,
        createDeviceForProvisionDialogOpen: false,
        newDeviceForProvision: { ...initialDeviceForProvisionFields }
      }
    case OPEN_EDIT_DEVICE_FOR_PROVISION_DIALOG:
      return {
        ...state,
        editDeviceForProvisionDialogOpen: true,
        editedDeviceForProvision: action.deviceForProvision
      }
    case CLOSE_EDIT_DEVICE_FOR_PROVISION_DIALOG:
      return {
        ...state,
        editDeviceForProvisionDialogOpen: false,
        editedDeviceForProvision: { ...initialDeviceForProvisionFields, id: '' }
      }
    case OPEN_IMPORT_DEVICES_FOR_PROVISION_DIALOG:
      return {
        ...state,
        importDevicesForProvisionDialogOpen: true
      }
    case CLOSE_IMPORT_DEVICES_FOR_PROVISION_DIALOG:
      return {
        ...state,
        importDevicesForProvisionDialogOpen: false,
        importDevicesForProvisionFile: null,
        importDevicesForProvisionProfileId: ''
      }
    case NEW_DEVICE_FOR_PROVISION_VALUES_CHANGED:
      return {
        ...state,
        newDeviceForProvision: action.values
      }
    case OPEN_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG:
      return {
        ...state,
        generateCSVDialogOpen: true
      }
    case CLOSE_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG:
      return {
        ...state,
        generateCSVDialogOpen: false
      }
    case NEW_GENERATE_CSV_FROM_SERIAL_VALUES_CHANGED:
      return {
        ...state,
        csvExportStart: action.values
      }
    case EDITED_DEVICE_FOR_PROVISION_VALUES_CHANGED:
      return {
        ...state,
        editedDeviceForProvision: action.values
      }
    case CREATE_DEVICE_FOR_PROVISION_FAILED:
      window.alert(i18n.t('errors.create_device_for_provision_failed') + ': ' + action.error)
      return state
    case CREATE_DEVICE_FOR_PROVISION_SUCCESS:
      return {
        ...state,
        devicesForProvision: [...state.devicesForProvision, action.data],
        newDeviceForProvision: { ...initialDeviceForProvisionFields },
        createDeviceForProvisionDialogOpen: false
      }
    case UPDATE_DEVICE_FOR_PROVISION_FAILED:
      window.alert(i18n.t('errors.update_device_for_provision_failed') + ': ' + action.error)
      return state
    case UPDATE_DEVICE_FOR_PROVISION_SUCCESS:
      return {
        ...state,
        devicesForProvision: state.devicesForProvision.map(item => {
          if (item.id === action.data.id) {
            return { ...item, ...action.data }
          }
          return item
        }),
        editedDeviceForProvision: { ...initialDeviceForProvisionFields, id: '' },
        editDeviceForProvisionDialogOpen: false
      }
    case DELETE_DEVICE_FOR_PROVISION_SUCCESS:
      return {
        ...state,
        devicesForProvision: state.devicesForProvision.filter(item => item.id !== action.data.id),
        editedDeviceForProvision: { ...initialDeviceForProvisionFields, id: '' },
        editDeviceForProvisionDialogOpen: false
      }
    case DELETE_DEVICE_FOR_PROVISION_FAILED:
      window.alert(i18n.t('errors.delete_device_for_provision_failed') + ': ' + action.error)
      return state
    case IMPORT_DEVICES_FOR_PROVISION_FILE_CHANGED:
      return {
        ...state,
        importDevicesForProvisionFile: action.file
      }
    case IMPORT_DEVICES_FOR_PROVISION_PROFILE_ID_CHANGED:
      return {
        ...state,
        importDevicesForProvisionProfileId: action.profileId
      }
    case SET_DEVICES_ACTIVE_PAGE:
      return {
        ...state,
        devicesActivePage: action.pageNumber
      }
    case SET_DEVICES_PAGE_SIZE:
      return {
        ...state,
        devicesActivePage: 1,
        devicesPageSize: action.pageSize
      }
    case SEARCH_DEVICES_FOR_PROVISION:
      return {
        ...state,
        searchQuery: action.query
      }
    default:
      return state
  }
}

export default devicesForProvisionReducer
