import moment from 'moment'
import { type AlarmSettingsForDevice } from '../redux/alarm-settings/alarm-settings-types'
import { retrieveLanguage } from './languageStore'
import { enGB, fi } from 'date-fns/locale'
import { type Locale } from 'date-fns'
import type BaseState from '../interfaces/BaseState'
import { type AlarmType } from '../redux/alarm-settings/alarm-types'

/**
 * Returns if alarm is enabled in alarm settings
 * @param settings AlarmSettingsForDevice
 * @param alarmType AlarmType
 * @returns boolean if alarm is enabled
 */
export function isAlarmEnabled(settings: AlarmSettingsForDevice, alarmType: AlarmType): boolean {
  const field = alarmType.enabledKey
  if (field === undefined) return false
  return settings[field] === true
}

/**
 * Groups an array with type T by type G
 * @param arr array to group
 * @param getter function to group item of type T by
 * @returns array grouped by getter function result
 */
export function groupBy<T, G>(arr: T[], getter: (item: T) => G): Map<G, T[]> {
  const map = new Map<G, T[]>()
  arr.forEach((item) => {
    const key = getter(item)
    const collection = map.get(key)
    if (collection === undefined || collection === null) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export function parseCubicMeters(from: number): string {
  return `${(from / 1000.0).toFixed(2)} m\u00B3`
}

export const ON_TIME = 'ON_TIME'
export const OUT_OF_DATE = 'OUT_OF_DATE'
export const NOT_UPDATED = 'NOT_UPDATED'

export function compareTime(toBeCompared: moment.MomentInput): 'NOT_UPDATED' | 'OUT_OF_DATE' | 'ON_TIME' {
  if (moment().diff(toBeCompared) > 172800000) {
    return NOT_UPDATED
  } else if (moment().diff(toBeCompared) > 86400000) {
    return OUT_OF_DATE
  }
  return ON_TIME
}

export function dateToLocaleOrDefault(date: Date | undefined | null): string {
  let dateToConvert = date
  if (dateToConvert === undefined || dateToConvert === null) {
    dateToConvert = new Date()
  }
  return dateToConvert.toLocaleDateString('fi-FI')
}

export function dateToLocaleOrEmpty(date: Date | undefined | null): string {
  if (date === undefined || date === null) {
    return ''
  }

  return date.toLocaleDateString('fi-FI')
}

export const getLanguageAsDateFnsLocaleObject = (): Locale => {
  const lan = retrieveLanguage()
  switch (lan) {
    case 'fi': {
      return fi
    }
    case 'en': {
      return enGB
    }
    default: {
      return fi
    }
  }
}

export function isTimestampOverDayOld(unixTimestamp: number | undefined): boolean {
  if (unixTimestamp === undefined) {
    return true
  }
  const timestampMoment = moment.unix(unixTimestamp)
  const fullDaysSinceTimestamp = moment().diff(timestampMoment, 'days')
  return fullDaysSinceTimestamp >= 1.0
}

/**
 * Paginates a list
 * @param list List to paginate
 * @param page current page number
 * @param pageSize page size
 * @returns paginated list
 */
export function paginateList<T>(list: T[], page: number, pageSize: number): T[] {
  const startIndex = ((page - 1) * pageSize)
  return list.slice(startIndex, startIndex + pageSize)
}

export const stateWithLoadingTrue = <T extends BaseState>(state: T): T => ({ ...state, loading: state.loading + 1 })
export const stateWithLoadingFalse = <T extends BaseState>(state: T): T => ({ ...state, loading: state.loading - 1 })
