import React from 'react'
import { AppBar } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { makeStyles } from 'tss-react/mui'
import TableCell from '@mui/material/TableCell'
import styled from '@emotion/styled'

export const StyledTableCell = styled(TableCell)`
  font-size: 10pt;
  font-family: Roboto;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #12263f;
`

export const StyledInput = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'isIdInput'
}) <{ isIdInput: boolean }>`
  border: ${(props) => (props.isIdInput ? 'none' : '2px solid #EFF0F3')};
  justify-self: center;
  font-size: 10pt;
  color: #12263f;
`

export const GraphIconButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`

// Currently only used in basic info
export const ColoredLabel = ({ children }) => <span style={{ color: '#7B90AD', marginRight: '2em', fontSize: '12px' }}>{children}</span>
export const ColoredValue = ({ children }) => <span style={{ color: '#506174' }}>{children}</span>

// Only used for the tables shown in expanded row tabs
export const TableCellBordered = styled(TableCell)`
  border-bottom: 1px solid #D0DCEC;
`
// Only used for the tables head cells shown in expanded row tabs
export const TableHeadCellBordered = styled(TableCell)`
  border-bottom: 1px solid #D0DCEC;
  color: #6E84A3;
`

// Only for row extension
export const StyledAppBar = styled(AppBar)`
  border-radius: 4px 4px 0 0;
  background-color: #D0DCEC;
  padding-left: 2em;
  box-shadow: none;
  color: black;
`

export const useTabStyles = makeStyles()(() => {
  return {
    root: {
      '& .MuiButtonBase-root.MuiTab-root': {
        fontSize: 12,
        backgroundColor: '#95aac9',
        borderRadius: '8px 8px 0 0',
        marginRight: '1em',
        top: '14px',
        opacity: '1',
        minWidth: '160px',
        color: 'white'
      },
      '& .MuiTabs-indicator': {
        display: 'none'
      },
      '& .Mui-selected': {
        textDecoration: 'none',
        backgroundColor: '#ffffff !important',
        color: '#12263F !important',
        top: '10px'
      }
    },
    indicator: {
      display: 'none'
    },
    graphControls: {
      color: '#12263F',
      backgroundColor: '#D0DCEC',
      width: '1.5em',
      height: '100%',
      marginRight: '8px',
      borderRadius: '6px',
      boxShadow: 'none',
      '&:hover': {
        color: '#2D415A',
        backgroundColor: '#D0DCEC',
        boxShadow: 'none'
      }
    },
    graphSelects: {
      color: '#2D415A',
      backgroundColor: '#D0DCEC',
      width: '100%',
      margin: '0 1.5em 0 1.5em',
      borderRadius: '6px',
      boxShadow: 'none',
      '&:before': {
        display: 'none'
      },
      '& .MuiSelect-select': {
        padding: '6px 0 7px',
        border: 'none'
      }
    },
    tabsLastElement: {
      '&:last-child': {
        position: 'absolute',
        right: '0'
      }
    }
  }
})
