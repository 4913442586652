import React from 'react'
import { connect } from 'react-redux'
import { Table, TableBody, TableHead, TableCell, TableRow } from '@mui/material'
import {
  fetchDevicesForProvision,
  openCreateDeviceForProvisionDialog,
  openEditDeviceForProvisionDialog,
  openImportDevicesForProvisionDialog,
  setDevicesActivePage,
  setDevicesPageSize,
  fetchDeviceProfiles,
  openGenerateCSVFromGivenSerialDialog,
  setDevicesForProvisionSearchQuery
} from '../../../redux/devices-for-provision/devices-for-provision-actions'
import { paginateList } from '../../../utils/utils'
import Fab from '@mui/material/Fab'
import Publish from '@mui/icons-material/Publish'
import GetApp from '@mui/icons-material/GetApp'
import ContentAdd from '@mui/icons-material/Add'
import Build from '@mui/icons-material/Build'
import CreateDeviceForProvisionDialog from './CreateDeviceForProvisionDialog'
import EditDeviceForProvisionDialog from './EditDeviceForProvisionDialog'
import ImportDevicesForProvisionDialog from './ImportDevicesForProvisionDialog'
import GenerateCSVFromGivenSerialDialog from './GenerateCSVFromGivenSerialDialog'
import MetersPagination from '../../common/pagination/MetersPagination'
import SearchBar from '../../common/search-bar/SearchBar'
import { searchDevicesForProvision } from '../../../utils/devices-for-provision-utils'
import { withTranslation } from 'react-i18next'

class DevicesForProvision extends React.Component {
  handlePageChange (pageNumber) {
    this.props.dispatch(setDevicesActivePage(pageNumber))
  }

  changePageSize (newPageSize) {
    this.props.dispatch(setDevicesPageSize(newPageSize))
  }

  componentDidMount () {
    this.props.dispatch(fetchDevicesForProvision())
  }

  openCreateDeviceForProvisionDialog () {
    if (!this.props.deviceProfilesLoaded) {
      this.props.dispatch(fetchDeviceProfiles())
    }
    this.props.dispatch(openCreateDeviceForProvisionDialog())
  }

  openImportDevicesForProvisionDialog () {
    if (!this.props.deviceProfilesLoaded) {
      this.props.dispatch(fetchDeviceProfiles())
    }
    this.props.dispatch(openImportDevicesForProvisionDialog())
  }

  updateSearchQuery = (query) => {
    this.props.dispatch(setDevicesForProvisionSearchQuery(query))
  }

  render () {
    const { t } = this.props

    const searchQuery = this.props.searchQuery
    const filteredDevices = searchDevicesForProvision(this.props.devicesForProvision, searchQuery)

    const paginatedDevices = paginateList(
      filteredDevices,
      this.props.devicesActivePage,
      this.props.devicesPageSize)

    return (
      <div style={{ marginBottom: '120px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchBar
            searchValue={searchQuery}
            onChange={(e) => { this.updateSearchQuery(e.target.value) }}
          />
          <Fab
            style={{ margin: '12px' }}
            color='primary'
            onClick={() => { this.openImportDevicesForProvisionDialog() }}
          >
            <Publish data-tip={t('send_csv')} />
          </Fab>
          <Fab
            color='primary'
            style={{ margin: '12px' }}
            onClick={() => { this.openCreateDeviceForProvisionDialog() }}
          >
            <ContentAdd />
          </Fab>
          <Fab
            color='primary'
            style={{ margin: '12px' }}
            onClick={() => this.props.dispatch(openGenerateCSVFromGivenSerialDialog())}
          >
            <GetApp data-tip={t('download_csv')} />
          </Fab>
        </div>
        <CreateDeviceForProvisionDialog />
        <EditDeviceForProvisionDialog />
        <ImportDevicesForProvisionDialog />
        <GenerateCSVFromGivenSerialDialog />
        <Table style={{ backgroundColor: 'white' }}>
          <TableHead>
            <TableRow>
              <TableCell>Serial Number</TableCell>
              <TableCell>Dev EUI</TableCell>
              <TableCell>App EUI</TableCell>
              <TableCell>App Key</TableCell>
              <TableCell>Profile</TableCell>
              <TableCell style={{ width: '100px' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedDevices.map(dfp => {
              return (
                <TableRow key={dfp.devEUI}>
                  <TableCell>{dfp.serialNumber}</TableCell>
                  <TableCell>{dfp.devEUI}</TableCell>
                  <TableCell>{dfp.appEUI}</TableCell>
                  <TableCell>{dfp.appKey}</TableCell>
                  <TableCell>{dfp.deviceProfileId}</TableCell>
                  <TableCell style={{ width: '100px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', paddingRight: '50px' }}>
                      <Fab
                        color='primary'
                        mini='true'
                        style={{ marginLeft: '10px' }}
                        key={dfp.devEUI}
                        onClick={() => {
                          if (!this.props.deviceProfilesLoaded) {
                            this.props.dispatch(fetchDeviceProfiles())
                          }
                          this.props.dispatch(openEditDeviceForProvisionDialog(dfp))
                        }}
                      >
                        <Build />
                      </Fab>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <MetersPagination
          activePage={this.props.devicesActivePage}
          itemsCountPerPage={this.props.devicesPageSize}
          totalItemsCount={filteredDevices.length}
          onPageChange={this.handlePageChange.bind(this)}
          onPageSizeChange={(event) => { this.changePageSize(event.target.value) }}
        />
      </div>
    )
  }
}

function mapStateToProps (state) {
  const {
    devicesForProvision,
    deviceProfilesLoaded,
    devicesActivePage,
    devicesPageSize,
    searchQuery
  } = state.vesimittari.devicesForProvisionReducer
  return {
    devicesForProvision,
    deviceProfilesLoaded,
    devicesActivePage,
    devicesPageSize,
    searchQuery
  }
}

export default withTranslation()(connect(mapStateToProps)(DevicesForProvision))
