import styled from '@emotion/styled'
import { Chip, FormControl } from '@mui/material'

export const ActiveFiltersContainer = styled.div`
  display: flex;
`
export const StyledChip = styled(Chip)`
    border-radius: 5px;
    background-color: #6e84a3;
    padding: 20px;
    color: #333333;
    margin: 0 10px 0 0;
    filter: brightness(140%);
    font-weight: 600;
    font-size: 12px;
    font-family: Roboto
`
export const StyledFormControl = styled(FormControl)`
    min-width: 160px;
    margin: 14px 0 0 10px;
`
export const FiltersContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 16px;
`
export const AddButtonContainer = styled.div`
    height: 40px;
    width: 40px;
    display: flex;
    background-color: #2c7be5;
    border-radius: 5px;
    :hover {
        cursor: pointer;
    }
`
