import AlarmSettingsApi from './alarm-settings-api'
import CompaniesApi from './companies-api'
import MeterDataApi from './meter-data-api'
import AdminApi from './admin-api'
import SettingsApi from './settings-api'
import ReportsApi from './reports-api'

export default {
  AlarmSettingsApi,
  CompaniesApi,
  MeterDataApi,
  AdminApi,
  SettingsApi,
  ReportsApi
}
