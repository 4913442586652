import { type AnyAction } from 'redux'
import * as actions from './meter-actions'
import { type MeterState } from './meter-types'

const initialState: MeterState = {
  newMeterValues: null
}

export default function meterReducer(state: MeterState = initialState, action: AnyAction): MeterState {
  switch (action.type) {
    case actions.SetNewMeterValuesAction.type: {
      return { ...state, newMeterValues: action.payload }
    }
    case actions.ToggleMeterSettingsDialogAction.type: {
      return { ...state, newMeterValues: action.payload }
    }
    default: {
      return state
    }
  }
}
