import { API_URL } from '../../constants/urls'
import { handleErrors, ApiError, errorAlert } from '../error/error-utils'
import {
  FETCH_DEVICES_FOR_PROVISION_SUCCESS,
  FETCH_DEVICES_FOR_PROVISION_FAILED,
  UPLOAD_DEVICES_FOR_PROVISION_CSV_FAILED,
  UPLOAD_DEVICES_FOR_PROVISION_CSV_SUCCESS,
  OPEN_CREATE_DEVICE_FOR_PROVISION_DIALOG,
  CLOSE_CREATE_DEVICE_FOR_PROVISION_DIALOG,
  OPEN_EDIT_DEVICE_FOR_PROVISION_DIALOG,
  CLOSE_EDIT_DEVICE_FOR_PROVISION_DIALOG,
  NEW_DEVICE_FOR_PROVISION_VALUES_CHANGED,
  EDITED_DEVICE_FOR_PROVISION_VALUES_CHANGED,
  CREATE_DEVICE_FOR_PROVISION_SUCCESS,
  CREATE_DEVICE_FOR_PROVISION_FAILED,
  UPDATE_DEVICE_FOR_PROVISION_FAILED,
  UPDATE_DEVICE_FOR_PROVISION_SUCCESS,
  DELETE_DEVICE_FOR_PROVISION_FAILED,
  DELETE_DEVICE_FOR_PROVISION_SUCCESS,
  OPEN_IMPORT_DEVICES_FOR_PROVISION_DIALOG,
  CLOSE_IMPORT_DEVICES_FOR_PROVISION_DIALOG,
  IMPORT_DEVICES_FOR_PROVISION_PROFILE_ID_CHANGED,
  IMPORT_DEVICES_FOR_PROVISION_FILE_CHANGED,
  FETCH_DEVICE_PROFILES_FAILED,
  FETCH_DEVICE_PROFILES_SUCCESS,
  FETCH_DEVICE_PROFILES_STARTED,
  SET_DEVICES_ACTIVE_PAGE,
  SET_DEVICES_PAGE_SIZE,
  OPEN_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG,
  CLOSE_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG,
  NEW_GENERATE_CSV_FROM_SERIAL_VALUES_CHANGED,
  SEARCH_DEVICES_FOR_PROVISION
} from '../../constants/action-types.ts'
import { LogoutSuccessAction } from '../auth/auth-actions'

function fetchDevicesForProvisionSuccess (data) {
  return {
    type: FETCH_DEVICES_FOR_PROVISION_SUCCESS,
    data
  }
}

function fetchDevicesForProvisionFailed (error) {
  return {
    type: FETCH_DEVICES_FOR_PROVISION_FAILED,
    error
  }
}

function uploadDevicesForProvisionCsvSuccess (data) {
  return {
    type: UPLOAD_DEVICES_FOR_PROVISION_CSV_SUCCESS,
    data
  }
}

function uploadDevicesForProvisionCsvFailed (error) {
  return {
    type: UPLOAD_DEVICES_FOR_PROVISION_CSV_FAILED,
    error
  }
}

function createDeviceForProvisionSuccess (data) {
  return {
    type: CREATE_DEVICE_FOR_PROVISION_SUCCESS,
    data
  }
}

function updateDeviceForProvisionSuccess (data) {
  return {
    type: UPDATE_DEVICE_FOR_PROVISION_SUCCESS,
    data
  }
}

function updateDeviceForProvisionFailed (error) {
  return {
    type: UPDATE_DEVICE_FOR_PROVISION_FAILED,
    error
  }
}

function createDeviceForProvisionFailed (error) {
  return {
    type: CREATE_DEVICE_FOR_PROVISION_FAILED,
    error
  }
}

function deleteDeviceForProvisionFailed (error) {
  return {
    type: DELETE_DEVICE_FOR_PROVISION_FAILED,
    error
  }
}

function deleteDeviceForProvisionSuccess (data) {
  return {
    type: DELETE_DEVICE_FOR_PROVISION_SUCCESS,
    data
  }
}

function fetchDeviceProfilesStarted () {
  return {
    type: FETCH_DEVICE_PROFILES_STARTED
  }
}

function fetchDeviceProfilesFailed (error) {
  return {
    type: FETCH_DEVICE_PROFILES_FAILED,
    error
  }
}

function fetchDeviceProfilesSuccess (data) {
  return {
    type: FETCH_DEVICE_PROFILES_SUCCESS,
    data
  }
}

export function openCreateDeviceForProvisionDialog () {
  return {
    type: OPEN_CREATE_DEVICE_FOR_PROVISION_DIALOG
  }
}

export function closeCreateDeviceForProvisionDialog () {
  return {
    type: CLOSE_CREATE_DEVICE_FOR_PROVISION_DIALOG
  }
}

export function openGenerateCSVFromGivenSerialDialog () {
  return {
    type: OPEN_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG
  }
}

export function closeGenerateCSVFromGivenSerialDialog () {
  return {
    type: CLOSE_GENERATE_CSV_FROM_GIVEN_SERIAL_DIALOG
  }
}

export function newGenerateCSVFromSerialValuesChanged (value) {
  return {
    type: NEW_GENERATE_CSV_FROM_SERIAL_VALUES_CHANGED,
    value
  }
}

export function openEditDeviceForProvisionDialog (deviceForProvision) {
  return {
    type: OPEN_EDIT_DEVICE_FOR_PROVISION_DIALOG,
    deviceForProvision
  }
}

export function closeEditDeviceForProvisionDialog () {
  return {
    type: CLOSE_EDIT_DEVICE_FOR_PROVISION_DIALOG
  }
}

export function openImportDevicesForProvisionDialog () {
  return {
    type: OPEN_IMPORT_DEVICES_FOR_PROVISION_DIALOG
  }
}

export function closeImportDevicesForProvisionDialog () {
  return {
    type: CLOSE_IMPORT_DEVICES_FOR_PROVISION_DIALOG
  }
}

export function newDeviceForProvisionValuesChanged (values) {
  return {
    type: NEW_DEVICE_FOR_PROVISION_VALUES_CHANGED,
    values
  }
}

export function editedDeviceForProvisionValuesChanged (values) {
  return {
    type: EDITED_DEVICE_FOR_PROVISION_VALUES_CHANGED,
    values
  }
}

export function importDevicesForProvisionFileChanged (file) {
  return {
    type: IMPORT_DEVICES_FOR_PROVISION_FILE_CHANGED,
    file
  }
}

export function importDevicesForProvisionProfileIdChanged (profileId) {
  return {
    type: IMPORT_DEVICES_FOR_PROVISION_PROFILE_ID_CHANGED,
    profileId
  }
}

export function updateEditedDeviceForProvision (data) {
  return dispatch => {
    fetch(`${API_URL}/device_for_provision/${data.id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(async response => await handleErrors(response))
      .then(async response => await response.json())
      .then(
        data => {
          if (data.error) {
            dispatch(updateDeviceForProvisionFailed(data.error))
          } else {
            dispatch(updateDeviceForProvisionSuccess(data))
          }
        }
      ).catch(error => {
        if (error instanceof ApiError && error.forceLogout) {
          dispatch(LogoutSuccessAction())
        }
        errorAlert('Laitteiden haku epäonnistui', error, dispatch)
      })
  }
}

export function createDeviceForProvision (data) {
  return dispatch => {
    fetch(`${API_URL}/device_for_provision`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(async response => await handleErrors(response))
      .then(async response => await response.json())
      .then(
        data => {
          if (data.error) {
            dispatch(createDeviceForProvisionFailed(data.error))
          } else {
            dispatch(createDeviceForProvisionSuccess(data))
          }
        }
      ).catch(error => {
        if (error instanceof ApiError && error.forceLogout) {
          dispatch(LogoutSuccessAction())
        }
        errorAlert('Laitteen luonti epäonnistui', error, dispatch)
      })
  }
}

export function deleteDeviceForProvision (id) {
  return dispatch => {
    fetch(`${API_URL}/device_for_provision/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json'
      }
    })
      .then(async response => await handleErrors(response))
      .then(async response => await response.json())
      .then(
        data => {
          if (data.error) {
            dispatch(deleteDeviceForProvisionFailed(data.error))
          } else {
            dispatch(deleteDeviceForProvisionSuccess(data))
          }
        }
      ).catch(error => {
        if (error instanceof ApiError && error.forceLogout) {
          dispatch(LogoutSuccessAction())
        }
        errorAlert('Laitteen poisto epäonnistui', error, dispatch)
      })
  }
}

export function fetchDevicesForProvision () {
  return dispatch => {
    dispatch(fetchDeviceProfilesStarted())
    fetch(`${API_URL}/device_for_provision`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`
      }
    })
      .then(async response => await handleErrors(response))
      .then(async response => await response.json())
      .then(
        data => {
          if (data.error) {
            dispatch(fetchDevicesForProvisionFailed(data.error))
          } else {
            dispatch(fetchDevicesForProvisionSuccess(data))
          }
        }
      ).catch(error => {
        if (error instanceof ApiError && error.forceLogout) {
          dispatch(LogoutSuccessAction())
        }
        errorAlert('Laitteiden haku epäonnistui', error, dispatch)
      })
  }
}

export function fetchDeviceProfiles () {
  return dispatch => {
    fetch(`${API_URL}/device_for_provision/profile_ids`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`
      }
    })
      .then(async response => await handleErrors(response))
      .then(async response => await response.json())
      .then(
        data => {
          if (data.error) {
            dispatch(fetchDeviceProfilesFailed(data.error))
          } else {
            dispatch(fetchDeviceProfilesSuccess(data))
          }
        }
      ).catch(error => {
        if (error instanceof ApiError && error.forceLogout) {
          dispatch(LogoutSuccessAction())
        }
        errorAlert('Laiteprofiilien haku epäonnistui', error, dispatch)
      })
  }
}

export function uploadDevicesForProvisionCsv (formData) {
  return dispatch => {
    fetch(`${API_URL}/device_for_provision/import_from_csv`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`
      },
      body: formData
    })
      .then(async response => await handleErrors(response))
      .then(async response => await response.json())
      .then(
        data => {
          if (!data.returnValue && data.error) {
            dispatch(uploadDevicesForProvisionCsvFailed(data.error))
          } else {
            dispatch(uploadDevicesForProvisionCsvSuccess(data))
          }
        }
      ).catch(error => {
        if (error instanceof ApiError && error.forceLogout) {
          dispatch(LogoutSuccessAction())
        }
        errorAlert('Laitteiden lähetys epäonnistui', error, dispatch)
      })
  }
}

export function setDevicesActivePage (pageNumber) {
  return {
    type: SET_DEVICES_ACTIVE_PAGE,
    pageNumber
  }
}

export function setDevicesPageSize (pageSize) {
  return {
    type: SET_DEVICES_PAGE_SIZE,
    pageSize
  }
}

export function setDevicesForProvisionSearchQuery (query) {
  return {
    type: SEARCH_DEVICES_FOR_PROVISION,
    query
  }
}
