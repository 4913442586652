import { createAction } from '@reduxjs/toolkit'
import {
  ADD_ACTIVE_FILTER,
  ADD_ACTIVE_LABEL_FILTER,
  CLOSE_REMOVE_METER_DIALOG,
  CUSTOMER_FILTER_CHANGED,
  FETCH_METERS,
  LABEL_FILTER_CHANGED,
  METERS_RECEIVED,
  METER_REMOVED,
  NEW_METER_VALUES_UPDATE_SUCCESS,
  OPEN_REMOVE_METER_DIALOG,
  REMOVE_ACTIVE_FILTER,
  REMOVE_ACTIVE_LABEL_FILTER,
  REMOVE_METER,
  SEARCH_STRING_CHANGED,
  SET_ALARMS_FILTER,
  SET_METER_ACTIVE_PAGE,
  SET_METER_PAGE_SIZE,
  TOGGLE_DOWNLOAD_DIALOG,
  TOGGLE_REMOVE_METER_CONFIRMED,
  UPDATE_OUT_OF_DATE_METER_ARRAY,
  SET_EDIT_COMPANY_ALARMS_DIALOG_VISIBILITY,
  SET_COMPANY_ALARM,
  SEARCH_METERS_DATA_ACTION,
  FETCH_METERS_FAILED
} from '../../constants/action-types'
import { type Meter } from '../meters/meter-types'
import {
  type AddActiveFilterPayload,
  type AddActiveLabelPayload,
  type CustomerFilterChangedPayload,
  type MetersReceivedPayload,
  type RemoveActiveFilterPayload,
  type RemoveActiveLabelFilterPayload,
  type RemoveMeterPayload,
  type SetAlarmsFilterPayload,
  type UpdateLabelFilterChangedPayload,
  type UpdateOutOfDateMeterArrayPayload
} from './meter-table-types'
import { type CompanyAlarm } from '../companies/companies-types'

export const ToggleDownloadDialogAction = createAction<void>(TOGGLE_DOWNLOAD_DIALOG)

export const SetMeterActivePageAction = createAction<number>(SET_METER_ACTIVE_PAGE)
export const SearchMetersDataAction = createAction<string>(SEARCH_METERS_DATA_ACTION)
export const SetMeterPageSizeAction = createAction<number>(SET_METER_PAGE_SIZE)
export const CustomerFilterChangedAction = createAction<CustomerFilterChangedPayload>(CUSTOMER_FILTER_CHANGED)
export const UpdateLabelFilterChangedAction = createAction<UpdateLabelFilterChangedPayload>(LABEL_FILTER_CHANGED)
export const UpdateOutOfDateMeterArrayAction = createAction<UpdateOutOfDateMeterArrayPayload>(UPDATE_OUT_OF_DATE_METER_ARRAY)
export const MeterRemovedAction = createAction<void>(METER_REMOVED)
export const ToggleRemoveMeterConfirmedAction = createAction<void>(TOGGLE_REMOVE_METER_CONFIRMED)
export const OpenRemoveMeterDialogAction = createAction<void>(OPEN_REMOVE_METER_DIALOG)
export const CloseRemoveMeterDialogAction = createAction<void>(CLOSE_REMOVE_METER_DIALOG)
export const NewMeterValuesUpdateSuccessAction = createAction<Meter >(NEW_METER_VALUES_UPDATE_SUCCESS) // TODO: this const is also used in the meter-actions, but it's not called anywhere from there
export const RemoveActiveLabelFilterAction = createAction<RemoveActiveLabelFilterPayload >(REMOVE_ACTIVE_LABEL_FILTER)
export const AddActiveLabelAction = createAction<AddActiveLabelPayload>(ADD_ACTIVE_LABEL_FILTER)
export const RemoveActiveFilterAction = createAction<RemoveActiveFilterPayload>(REMOVE_ACTIVE_FILTER)
export const AddActiveFilterAction = createAction<AddActiveFilterPayload>(ADD_ACTIVE_FILTER)
export const SetAlarmsFilterAction = createAction< SetAlarmsFilterPayload>(SET_ALARMS_FILTER)
export const SearchStringChangedAction = createAction<string>(SEARCH_STRING_CHANGED)
export const MetersReceivedAction = createAction<MetersReceivedPayload>(METERS_RECEIVED)
export const FetchMetersFailureAction = createAction<void>(FETCH_METERS_FAILED)
export const FetchMetersAction = createAction<void>(FETCH_METERS)
export const RemoveMeterAction = createAction<RemoveMeterPayload >(REMOVE_METER)

export const SetCompanyAlarmDialogVisibilityAction = createAction<boolean>(SET_EDIT_COMPANY_ALARMS_DIALOG_VISIBILITY)
export const SetCompanyAlarmAction = createAction<CompanyAlarm>(SET_COMPANY_ALARM)
