import { Button } from '@mui/material'
import styled from '@emotion/styled'

export const MetersViewContainer = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    background-color: #f9fbfd;
`
export const MetersHeader = styled.p`
  font-weight: 600;
  font-size: 18px;
  font-family: Roboto;
  color: #12263f;
`
export const MainMetersActionButton = styled(Button)`
  text-transform: inherit;
  font-family: Roboto;
  color: #12263f;
  font-weight: 600;
  margin: 0 10px
`

export const GenerateTransferFileDialogActionButton = styled(Button)`
  text-transform: inherit;
  font-family: Roboto;
  color: #12263f;
  font-weight: 600;
  margin: 0 10px;
`

export const DownloadActiveMetersReportButton = styled(Button)`
  text-transform: inherit;
  font-family: Roboto;
  color: #12263f;
  font-weight: 600;
  margin: 0 10px;
`

export const SetCompanyAlarmButton = styled(Button)`
  text-transform: inherit;
  font-family: Roboto;
  color: #12263f;
  font-weight: 600;
  margin: 0 10px;
  svg {
    width: 0.85em;
    height: 0.85em;
  }
`
