import React, { type ChangeEvent, useEffect, useState } from 'react'
import {
  Dialog,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  FormControlLabel,
  Divider,
  Checkbox
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import LoraLogo from '../../../assets/lora.png'
import SigfoxLogo from '../../../assets/sigfox.png'
import SigfoxNoTextLogo from '../../../assets/sigfox-no-text.png'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../interfaces/RootState'
import { isAlarmEnabled } from '../../../utils/utils'
import LabelsInput from './LabelsInput'
import {
  StyledAppBar,
  SettingsSaveButton,
  SettingsTabTableCell,
  StyledBaseReading,
  StyledBaseReadingTextField,
  StyledFieldsText,
  StyledSettingsTableCell,
  StyledTitle,
  SettingsGeneralButton,
  StyledLabelFullWidth
} from './MeterConfiguration.style'
import clsx from 'clsx'
import {
  ChangeAlarmSettingsAction,
  FetchAlarmSettingsForDeviceAction
} from '../../../redux/alarm-settings/alarm-settings-actions'
import { StyledInput } from '../meters-table/meters-table-row/MetersTableRow.style'
import {
  SetNewMeterValuesAction,
  ToggleMeterSettingsDialogAction,
  UpdateNewMeterValuesAction
} from '../../../redux/meters/meter-actions'
import { errorAlert } from '../../../redux/error/error-utils'
import { meterSupportsAlarm, } from '../../../utils/meter-utils'
import { isPulseDevice } from '../../../redux/meters/meter-types'
import DeleteMeterButton from './DeleteMeter'
import {
  OpenEmptyLabelErrorAction,
  OpenErrorAction,
  OpenSameLabelErrorAction
} from '../../../redux/error/error-actions'
import { useTranslation } from 'react-i18next'
import {
  type AlarmType,
  AlarmTypes,
  FLOW_LIMIT,
  getAlarmTypeByKey,
  getNameTranslationKey
} from '../../../redux/alarm-settings/alarm-types'

const useStyles = makeStyles()(() => {
  return {
    root: {
      flexGrow: 1
    },
    tabs: {
      '& .MuiButtonBase-root.MuiTab-root': {
        fontSize: 12,
        backgroundColor: '#95aac9',
        borderRadius: '5px 5px 0 0',
        margin: '16px 16px 0 16px'
      },
      '& .MuiTabs-indicator': {
        display: 'none'
      },
      '& .MuiTab-textColorInherit': {
        color: 'inherit',
        opacity: 0.7
      },
      '& .Mui-selected': {
        textDecoration: 'none',
        backgroundColor: '#ffffff !important',
        color: '#12263F'
      }
    },
    label: {
      '& .MuiFormControlLabel-label': {
        color: '#12263f',
        fontSize: 10
      }
    },
    icon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow:
        'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
        border: '1px solid #00D97D'
      }
    },
    checkedIcon: {
      backgroundColor: '#7CFFC8',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        content: '""'
      }
    }
  }
})

interface LabelData {
  label: string
}

interface DeviceAlarmsDialogActionsProps {
  newMeterValues: any
}

function DeviceAlarmsDialogActions({
  newMeterValues
}: DeviceAlarmsDialogActionsProps): JSX.Element {
  const dispatch = useDispatch()
  const t = useTranslation().t
  const stateAlarmSettings = useSelector(
    (state: RootState) => state.vesimittari.alarmSettings.alarmSettings
  )

  return (
    <>
      <DeleteMeterButton />
      <SettingsGeneralButton
        onClick={() => dispatch(ToggleMeterSettingsDialogAction(null))}
      >
        {t('settings_dialog.cancel')}
      </SettingsGeneralButton>
      <SettingsSaveButton
        onClick={() => {
          dispatch(
            UpdateNewMeterValuesAction({
              ...newMeterValues,
              alarmSettings: stateAlarmSettings
            })
          )
        }}
      >
        {t('settings_dialog.ok')}
      </SettingsSaveButton>
    </>
  )
}

interface AlarmSettingProps {
  alarmType: AlarmType
  rowNumber: number
  newMeterValues: any
}

function AlarmSetting({
  alarmType,
  rowNumber,
  newMeterValues
}: AlarmSettingProps): JSX.Element | null {
  const { classes } = useStyles()
  const stateAlarmSettings = useSelector(
    (state: RootState) => state.vesimittari.alarmSettings.alarmSettings
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const checked = isAlarmEnabled(stateAlarmSettings, alarmType)

  if (
    !stateAlarmSettings ||
    stateAlarmSettings.deviceId !== newMeterValues.id
  ) {
    return null
  }

  const flowLimitAlarmType = alarmType.alarmKey === FLOW_LIMIT.alarmKey
  const supported = meterSupportsAlarm(
    newMeterValues.type,
    alarmType
  )

  // Only updates/changes the wanted setting in state. This does not update AlarmSettings to backend
  function onAlarmSettingChange(
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void {
    const alarmSetting = getAlarmTypeByKey(event.currentTarget.id).enabledKey
    if (!alarmSetting || !stateAlarmSettings) {
      return
    }

    dispatch(
      ChangeAlarmSettingsAction({
        setting: alarmSetting,
        value: checked
      })
    )
  }

  function onFlowLimitChange(event: ChangeEvent<HTMLInputElement>): void {
    if (!stateAlarmSettings) {
      return
    }

    const limit = event.target.value === '' ? null : parseInt(event.target.value)
    if (limit !== null && isNaN(limit)) {
      dispatch(OpenErrorAction(t('errors.flow_limit_invalid')))
    } else {
      dispatch(
        ChangeAlarmSettingsAction({ setting: 'maxFlowPerHour', value: limit })
      )
    }
  }

  const flowValue: string =
    flowLimitAlarmType && stateAlarmSettings.maxFlowPerHour != null
      ? stateAlarmSettings.maxFlowPerHour + ''
      : ''

  return (
    <TableRow style={{ opacity: !supported ? '0.38' : '' }}>
      <SettingsTabTableCell>{rowNumber + 1}</SettingsTabTableCell>
      <SettingsTabTableCell>
        {t(getNameTranslationKey(alarmType))}
      </SettingsTabTableCell>
      <SettingsTabTableCell>
        {supported
          ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              className={classes.label}
              label=""
              control={
                <Checkbox
                  checked={checked}
                  onChange={onAlarmSettingChange}
                  icon={<span className={classes.icon} />}
                  checkedIcon={
                    <span
                      className={clsx(
                        classes.icon,
                        classes.checkedIcon
                      )}
                    />
                  }
                  id={alarmType.alarmKey}
                  data-testid={alarmType.enabledKey + '-checkbox'}
                />
              }
            />
            {flowLimitAlarmType
              ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '40px'
                }}
              >
                <StyledBaseReadingTextField
                  value={flowValue}
                  onChange={onFlowLimitChange}
                  data-testid="flowLimitTextField"
                />
                <StyledBaseReading>l/h</StyledBaseReading>
              </div>
                )
              : null}
          </div>
            )
          : (
              t('settings_dialog.meter_alarm_unsupported')
            )}
      </SettingsTabTableCell>
    </TableRow>
  )
}

function TabPanel({
  children,
  tabId,
  currentTabId
}: {
  children: JSX.Element | JSX.Element[]
  tabId: number
  currentTabId: number
}): JSX.Element {
  return <div hidden={currentTabId !== tabId}>{children}</div>
}

function MeterLogo({
  isLora,
  small
}: {
  isLora: boolean
  small?: boolean
}): JSX.Element {
  const src = isLora ? LoraLogo : small ? SigfoxNoTextLogo : SigfoxLogo

  return <img src={src} alt="lora-logo" style={{ width: '35px' }} />
}

function MeterGeneralInfo({
  newMeterValues
}: {
  newMeterValues: any
}): JSX.Element {
  const dispatch = useDispatch()
  const t = useTranslation().t

  function onLocationChange(e: ChangeEvent<HTMLInputElement>): void {
    dispatch(
      SetNewMeterValuesAction({
        ...newMeterValues,
        location: e.target.value
      })
    )
  }

  function onSerialNumberChange(e: ChangeEvent<HTMLInputElement>): void {
    dispatch(
      SetNewMeterValuesAction({
        ...newMeterValues,
        serialNumber: e.target.value
      })
    )
  }

  function onTransmitterSerialNumberChange(e: ChangeEvent<HTMLInputElement>): void {
    dispatch(
      SetNewMeterValuesAction({
        ...newMeterValues,
        transmitterSerialNumber: e.target.value
      })
    )
  }

  const isPulseMeter = isPulseDevice(newMeterValues.type)

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledSettingsTableCell />
          <StyledSettingsTableCell>{t('radio_id')}</StyledSettingsTableCell>
          <StyledSettingsTableCell>
            {t('usage_location_number')}
          </StyledSettingsTableCell>
          <StyledSettingsTableCell>
            {t('serial_number')}
          </StyledSettingsTableCell>
          <StyledSettingsTableCell>
            {t('transmitter_serial_number')}
          </StyledSettingsTableCell>
          <StyledSettingsTableCell>
            {t('pulse_liter_per_impulse')}
          </StyledSettingsTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ display: 'flex', alignItems: 'center' }}>
            <MeterLogo isLora={newMeterValues.isLora} small />
            {isPulseMeter && (
              <span style={{ color: '#0080FF' }}>&nbsp;Pulse</span>
            )}
          </TableCell>
          <TableCell>{newMeterValues.sigfoxId}</TableCell>
          <TableCell>
            <StyledInput
              id="meter_location"
              value={newMeterValues.location}
              onChange={onLocationChange}
              isIdInput={false}
              inputProps={{ 'data-testid': 'meterLocationChangeInput' }}
            />
          </TableCell>
          <TableCell>
            <StyledInput
              id="serial_number"
              value={newMeterValues.serialNumber}
              onChange={onSerialNumberChange}
              isIdInput={false}
              inputProps={{ 'data-testid': 'serialNumberChangeInput' }}
            />
          </TableCell>
          <TableCell>
            <StyledInput
              id="transmitter_serial_number"
              value={newMeterValues.transmitterSerialNumber}
              onChange={onTransmitterSerialNumberChange}
              isIdInput={false}
              inputProps={{ 'data-testid': 'transmitterSerialNumberChangeInput' }}
            />
          </TableCell>
          <TableCell>
            {isPulseMeter ? newMeterValues.pulseInLiters : '--'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

function MeterBaseValue({
  newMeterValues
}: {
  newMeterValues: any
}): JSX.Element {
  const dispatch = useDispatch()
  const t = useTranslation().t

  function onChange(e: ChangeEvent<HTMLInputElement>): void {
    const baseValue = e.currentTarget.value
    const parsed = parseInt((Math.floor(+baseValue * 100) * 10).toString(), 10)

    if (isNaN(parsed)) {
      errorAlert(t('base_value_invalid'), '', dispatch)
    } else {
      dispatch(
        SetNewMeterValuesAction({
          ...newMeterValues,
          baseValue: parsed
        })
      )
    }
  }

  return (
    <div style={{ padding: '10px 0' }}>
      <StyledFieldsText data-tip={t('settings_dialog.base_reading_tooltip')}>
        {t('settings_dialog.base_reading_info_text')}
      </StyledFieldsText>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledBaseReadingTextField
          id="MeterBaseValueTextField"
          data-testid="MeterBaseValueTextField"
          value={newMeterValues && newMeterValues.baseValue / 1000}
          onChange={onChange}
        />
        <StyledBaseReading>{`m${String.fromCharCode(179)}`}</StyledBaseReading>
      </div>
    </div>
  )
}

function MeterLabels({ newMeterValues }: { newMeterValues: any }): JSX.Element {
  const dispatch = useDispatch()
  const [labelValue, setLabelValue] = useState('')
  const [labelList, setLabelList] = useState<any>([])
  const t = useTranslation().t

  useEffect(() => {
    if (newMeterValues.labels) {
      setLabelList(newMeterValues.labels)
    }
  }, [newMeterValues])

  function onLabelValueChange(e: ChangeEvent<HTMLInputElement>): void {
    setLabelValue(e.target.value)
  }

  function onInsertLabel(e: MouseEvent): void {
    e.preventDefault()

    setLabelValue('')
    if (labelValue === '') {
      dispatch(OpenEmptyLabelErrorAction())
    } else if (labelList.includes(labelValue)) {
      dispatch(OpenSameLabelErrorAction())
    } else {
      const newLabels: string[] = []
      for (const l of labelValue.split(',')) {
        if (l.trim() !== '') {
          newLabels.push(l.trim())
        }
      }

      dispatch(
        SetNewMeterValuesAction({
          ...newMeterValues,
          labels: [...newMeterValues.labels, ...newLabels]
        })
      )
    }
  }

  function handleLabelDelete(labelToDelete: LabelData): void {
    const filteredLabels = newMeterValues.labels.filter(
      (label) => label !== labelToDelete
    )
    dispatch(
      SetNewMeterValuesAction({
        ...newMeterValues,
        labels: filteredLabels
      })
    )
  }

  return (
    <div style={{ padding: '10px 0' }}>
      <StyledFieldsText>{t('main_meters.labels')}</StyledFieldsText>
      <LabelsInput
        labelValueChange={onLabelValueChange}
        insertLabelHandler={onInsertLabel}
        labelValue={labelValue}
      />
      {newMeterValues.labels && (
        <div style={{ margin: '16px 0' }}>
          {labelList.map((item, index) => {
            return (
              <StyledLabelFullWidth
                key={index}
                label={item}
                onDelete={() => { handleLabelDelete(item) }}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

function MeterAlarms({ newMeterValues }: { newMeterValues: any }): JSX.Element {
  const { t } = useTranslation()
  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledSettingsTableCell>{'#'}</StyledSettingsTableCell>
          <StyledSettingsTableCell>{t('alarm')}</StyledSettingsTableCell>
          <StyledSettingsTableCell>{t('state')}</StyledSettingsTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { (Object.values(AlarmTypes)).map((alarmType, index) => (
          <AlarmSetting
            key={alarmType.alarmKey}
            alarmType={alarmType}
            rowNumber={index}
            newMeterValues={newMeterValues}
          />
        ))
        }
      </TableBody>
    </Table>
  )
}

export interface MeterConfigurationProps {
  meterSettingsDialogOpen: boolean
  newMeterValues: any
}

export function MeterConfiguration({
  meterSettingsDialogOpen,
  newMeterValues
}: MeterConfigurationProps) {
  const { classes } = useStyles()
  const [currentTab, setCurrentTab] = useState(0)
  const dispatch = useDispatch()
  const t = useTranslation().t
  const showAlarmsTab: boolean = newMeterValues.isLora && newMeterValues.latestLoraStatus

  // Only load these once when the modal has opened
  useEffect(() => {
    if (newMeterValues.id != null) {
      dispatch(
        FetchAlarmSettingsForDeviceAction({ deviceId: newMeterValues.id })
      )
      if (!showAlarmsTab) {
        setCurrentTab(1)
      }
    }
  }, [dispatch, newMeterValues, showAlarmsTab])

  return (
    <Dialog open={meterSettingsDialogOpen} maxWidth="md" fullWidth>
      <StyledTitle>{t('settings_dialog.meter_settings')}</StyledTitle>
      <DialogContent>
        <MeterGeneralInfo newMeterValues={newMeterValues} />
        <MeterBaseValue newMeterValues={newMeterValues} />
        <MeterLabels newMeterValues={newMeterValues} />

        {/* Tabs */}
        <div style={{ marginTop: '16px', border: '1px solid #edf2f9' }}>
          <div className={classes.root}>
            <StyledAppBar position="static">
              <Tabs
                className={classes.tabs}
                value={currentTab}
                onChange={(_, newTab) => { setCurrentTab(newTab) }}
                textColor="inherit"
              >
                {showAlarmsTab
                  ? (
                  <Tab
                    disableRipple
                    data-testid={'alarms-tab-button'}
                    label={t('alarms')}
                  />
                    )
                  : null}
              </Tabs>
            </StyledAppBar>
          </div>
          {showAlarmsTab
            ? (
            <TabPanel tabId={0} currentTabId={currentTab}>
              <MeterAlarms newMeterValues={newMeterValues} />
            </TabPanel>
              )
            : null}
        </div>
        <Divider style={{ margin: '20px 0' }} />
      </DialogContent>
      <DialogActions>
        <DeviceAlarmsDialogActions newMeterValues={newMeterValues} />
      </DialogActions>
    </Dialog>
  )
}

export default function ConnectedMeterConfiguration() {
  const newMeterValues = useSelector(
    (state: RootState): any => {
      return state.vesimittari.meterReducer.newMeterValues
    }
  )
  const meterSettingsDialogOpen = useSelector(
    (state: RootState): boolean =>
      state.vesimittari.meterTable.meterSettingsDialogOpen
  )
  if (!newMeterValues) {
    return null
  }

  return (
    <MeterConfiguration
      newMeterValues={newMeterValues}
      meterSettingsDialogOpen={meterSettingsDialogOpen}
    />
  )
}
